import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  quoteList: []
};

const slice = createSlice({
  name: 'quote',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET RECEIPTS
    getQuoteListSuccess(state, action) {
      state.isLoading = false;
      state.quoteList = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
// export const { onToggleFollow, deleteCustomer } = slice.actions;

// ----------------------------------------------------------------------

export function getQuoteList(selectedCompanyId, serie) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/v1/${selectedCompanyId}/quote/series/${serie}`);
      dispatch(slice.actions.getQuoteListSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
