import { useEffect, useState } from 'react';
// material
import PersonIcon from '@material-ui/icons/Person';
import {
  Box,
  List,
  Avatar,
  Button,
  Dialog,
  Typography,
  DialogTitle,
  ListItemText,
  ListItemAvatar,
  ListItemButton
} from '@material-ui/core';
import { getCompaniesList, setSelectedCompany } from '../redux/slices/user';
import { useDispatch, useSelector } from '../redux/store';

// ----------------------------------------------------------------------

const emails = ['username@gmail.com', 'user02@gmail.com'];

export default function CompanyDialog() {
  const dispatch = useDispatch();
  const { companiesList } = useSelector((state) => state.user);
  const [open, setOpen] = useState(true);

  useEffect(() => {
    dispatch(getCompaniesList());
  }, [dispatch]);

  const handleClose = (value) => {
    dispatch(setSelectedCompany(value.id));
    setOpen(false);
  };

  if (companiesList.length > 0) {
    return (
      <Box sx={{ textAlign: 'center' }}>
        <Dialog open={open}>
          <DialogTitle id="simple-dialog-title">Selecciona un empresa:</DialogTitle>
          <List>
            {companiesList.map((option) => (
              <ListItemButton onClick={() => handleClose(option)} key={option.id}>
                <ListItemAvatar>
                  <Avatar
                    sx={{
                      color: 'info.main',
                      backgroundColor: 'info.lighter'
                    }}
                  >
                    <PersonIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={option.name} />
              </ListItemButton>
            ))}
          </List>
        </Dialog>
      </Box>
    );
  }
  return null;
}
