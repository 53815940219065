// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// components
import SvgIconStyle from '../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
);

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  customer: getIcon('ic_customer'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  booking: getIcon('ic_booking'),
  user: getIcon('ic_user')
};

const sidebarConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'general',
    items: [
      {
        title: 'dashboard',
        path: PATH_DASHBOARD.general.app,
        icon: ICONS.dashboard
      },
      {
        title: 'facturas',
        path: PATH_DASHBOARD.invoice.root,
        icon: ICONS.ecommerce,
        children: [
          { title: 'Serie V', path: `${PATH_DASHBOARD.invoice.series}V` },
          { title: 'Serie C', path: `${PATH_DASHBOARD.invoice.series}C` },
          { title: 'Serie S', path: `${PATH_DASHBOARD.invoice.series}S` },
          { title: 'Serie T', path: `${PATH_DASHBOARD.invoice.series}T` }
        ]
      },
      {
        title: 'f. rectificativas',
        path: PATH_DASHBOARD.amend.root,
        icon: ICONS.banking,
        children: [{ title: 'Serie R', path: `${PATH_DASHBOARD.amend.series}R` }]
      },
      {
        title: 'albaranes',
        path: PATH_DASHBOARD.receipt.root,
        icon: ICONS.calendar,
        children: [
          { title: 'Serie A', path: `${PATH_DASHBOARD.receipt.series}A` },
          { title: 'Serie C', path: `${PATH_DASHBOARD.receipt.series}C` },
          { title: 'F. Simplificada', path: `${PATH_DASHBOARD.receipt.series}S` }
        ]
      },
      {
        title: 'F.Proforma',
        path: PATH_DASHBOARD.receipt.root,
        icon: ICONS.calendar,
        children: [{ title: 'Serie P', path: `${PATH_DASHBOARD.quote.series}P` }]
      }
      /* {
        title: 'presupuestos',
        path: PATH_DASHBOARD.quote.root,
        icon: ICONS.booking,
        children: [{ title: 'Proforma', path: `${PATH_DASHBOARD.receipt.series}P` }]
      } */
    ]
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'Mantenimiento',
    items: [
      // MANAGEMENT : USER
      /* {
        title: 'usuarios',
        path: PATH_DASHBOARD.user.root,
        icon: ICONS.user,
        children: [
          { title: 'Listado', path: PATH_DASHBOARD.user.list },
          { title: 'Consultas', path: PATH_DASHBOARD.user.query }
        ]
      }, */

      // MANAGEMENT : CUSTOMER
      {
        title: 'clientes',
        path: PATH_DASHBOARD.customer.list,
        icon: ICONS.customer,
        children: [
          { title: 'Listado', path: PATH_DASHBOARD.customer.list },
          { title: 'Consultas', path: PATH_DASHBOARD.customer.reports }
        ]
      },

      // MANAGEMENT : PRODUCT
      {
        title: 'productos',
        path: PATH_DASHBOARD.product.list,
        icon: ICONS.cart
      },

      // MANAGEMENT : SETTINGS
      {
        title: 'configuración',
        path: PATH_DASHBOARD.settings.root,
        icon: ICONS.kanban
      }
    ]
  }
];

export default sidebarConfig;
