import { Icon } from '@iconify/react';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import arrowIosBackFill from '@iconify/icons-eva/arrow-ios-back-fill';
import arrowIosForwardFill from '@iconify/icons-eva/arrow-ios-forward-fill';
// material
import {
  Card,
  List,
  Grid,
  Button,
  Divider,
  Checkbox,
  CardHeader,
  ListItemText,
  ListItemIcon,
  ListItemButton
} from '@material-ui/core';
import * as moment from 'moment';
import { toCurrency } from '../../../utils/formatNumber';

// ----------------------------------------------------------------------

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
  return [...a, ...not(b, a)];
}

ReceiptTransferList.propTypes = {
  customerPendingReceipts: PropTypes.arrayOf(
    PropTypes.shape({
      receiptNumber: PropTypes.string,
      date: PropTypes.string,
      amount: PropTypes.string
    })
  ),
  invoiceReceipts: PropTypes.arrayOf(
    PropTypes.shape({
      receiptNumber: PropTypes.string,
      date: PropTypes.string,
      amount: PropTypes.string
    })
  ),
  handleSelectedReceipts: PropTypes.func
};

export default function ReceiptTransferList({ customerPendingReceipts, invoiceReceipts, handleSelectedReceipts }) {
  const [checked, setChecked] = useState([]);
  const [left, setLeft] = useState(customerPendingReceipts);
  const [right, setRight] = useState(invoiceReceipts);
  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);
  const [amountLeft, setAmountLeft] = useState(0);
  const [amountRight, setAmountRight] = useState(0);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  const calculateReceiptTotal = (receipts) =>
    // eslint-disable-next-line array-callback-return
    receipts.reduce((acc, curr) => parseFloat(curr.total) + acc, 0).toFixed(2);

  useEffect(() => {
    setLeft(customerPendingReceipts);
  }, [customerPendingReceipts]);

  useEffect(() => {
    setAmountLeft(calculateReceiptTotal(left));
  }, [left]);

  useEffect(() => {
    setRight(invoiceReceipts);
  }, [invoiceReceipts]);

  useEffect(() => {
    setAmountRight(calculateReceiptTotal(right));
  }, [right]);

  const numberOfChecked = (items) => intersection(checked, items).length;

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    handleSelectedReceipts(right.concat(leftChecked));
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    handleSelectedReceipts(not(right, rightChecked));
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const customList = (title, receipts) => (
    <Card sx={{ borderRadius: 1.5 }}>
      <CardHeader
        avatar={
          <Checkbox
            onClick={handleToggleAll(receipts)}
            checked={numberOfChecked(receipts) === receipts.length && receipts.length !== 0}
            indeterminate={numberOfChecked(receipts) !== receipts.length && numberOfChecked(receipts) !== 0}
            disabled={receipts.length === 0}
            inputProps={{ 'aria-label': 'all items selected' }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(receipts)}/${receipts.length} seleccionados`}
        sx={{ p: 2 }}
      />

      <Divider />

      <List
        dense
        component="div"
        role="list"
        sx={{
          width: 400,
          height: 220,
          overflow: 'auto'
        }}
      >
        {receipts.map((receipt) => {
          const labelId = `transfer-list-all-item-${receipt}-label`;
          return (
            <ListItemButton key={receipt} role="listitem" onClick={handleToggle(receipt)}>
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(receipt) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={`${receipt.receiptNumber}`} />
              <ListItemText id={labelId} primary={`${moment(receipt.date).format('DD/MM/yy')}`} />
              <ListItemText id={labelId} primary={`${toCurrency(receipt.amount, 3)}`} />
            </ListItemButton>
          );
        })}
      </List>
    </Card>
  );

  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid item>{customList(`Pendientes  (${toCurrency(amountLeft)})`, left)}</Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center" sx={{ p: 3 }}>
          <Button
            color="inherit"
            variant="outlined"
            size="small"
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label="move selected right"
            sx={{ my: 1 }}
          >
            <Icon icon={arrowIosForwardFill} width={18} height={18} />
          </Button>
          <Button
            color="inherit"
            variant="outlined"
            size="small"
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="move selected left"
            sx={{ my: 1 }}
          >
            <Icon icon={arrowIosBackFill} width={18} height={18} />
          </Button>
        </Grid>
      </Grid>
      <Grid item>{customList(`Facturar  (${toCurrency(amountRight)})`, right)}</Grid>
    </Grid>
  );
}
