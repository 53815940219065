import { List, ListItemButton, ListItemText, Menu, MenuItem } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { getCompaniesList, setSelectedCompany } from '../redux/slices/user';
import { useDispatch, useSelector } from '../redux/store';

export default function CompanyDropDown() {
  const dispatch = useDispatch();
  const { companiesList, selectedCompanyId } = useSelector((state) => state.user);
  const [isOpenList, setOpenList] = useState(null);

  const handleClickListItem = (event) => {
    setOpenList(event.currentTarget);
  };

  const handleMenuItemClick = (event, index) => {
    dispatch(setSelectedCompany(index + 1));
    setOpenList(null);
  };

  useEffect(() => {
    dispatch(getCompaniesList());
  }, [dispatch]);

  if (companiesList.length > 0) {
    return (
      <>
        <List component="nav" aria-label="Device settings">
          <ListItemButton
            aria-haspopup="true"
            aria-controls="lock-menu"
            aria-label="when device is locked"
            onClick={handleClickListItem}
          >
            <ListItemText primary="Empresa seleccionada:" secondary={companiesList[selectedCompanyId - 1].name} />
          </ListItemButton>
        </List>
        <Menu keepMounted id="lock-menu" anchorEl={isOpenList} open={Boolean(isOpenList)}>
          {companiesList.map((option, index) => (
            <MenuItem
              key={option.id}
              selected={index === selectedCompanyId - 1}
              onClick={(event) => handleMenuItemClick(event, index)}
            >
              {option.name}
            </MenuItem>
          ))}
        </Menu>
      </>
    );
  }
  return null;
}
