// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  verify: path(ROOTS_AUTH, '/verify')
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page404: '/404',
  page500: '/500',
  components: '/components'
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, '/dashboard')
  },
  invoice: {
    root: path(ROOTS_DASHBOARD, '/invoice'),
    series: path(ROOTS_DASHBOARD, '/invoice/series/')
  },
  amend: {
    root: path(ROOTS_DASHBOARD, '/amend'),
    series: path(ROOTS_DASHBOARD, '/amend/series/')
  },
  receipt: {
    root: path(ROOTS_DASHBOARD, '/receipt'),
    series: path(ROOTS_DASHBOARD, '/receipt/series/')
  },
  quote: {
    root: path(ROOTS_DASHBOARD, '/quote'),
    series: path(ROOTS_DASHBOARD, '/quote/series/')
  },
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    query: path(ROOTS_DASHBOARD, '/user/query')
  },
  customer: {
    root: path(ROOTS_DASHBOARD, '/customer'),
    list: path(ROOTS_DASHBOARD, '/customer/list'),
    newCustomer: path(ROOTS_DASHBOARD, '/customer/new'),
    reports: path(ROOTS_DASHBOARD, '/customer/reports')
  },
  product: {
    root: path(ROOTS_DASHBOARD, '/product'),
    list: path(ROOTS_DASHBOARD, '/product/list'),
    newProduct: path(ROOTS_DASHBOARD, '/product/new')
  },
  settings: {
    root: path(ROOTS_DASHBOARD, '/settings')
  }
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
