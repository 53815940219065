import PropTypes from 'prop-types';
// material
import { useTheme } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object
};

export default function Logo({ sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  return (
    <Box sx={{ width: 64, height: 40, ...sx }}>
      <svg width="65px" height="58px" viewBox="0 0 65 58" version="1.1">
        <title>Group</title>
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="Group" fill="#DF2221">
            <path
              d="M0.0165490079,19.6 C-0.383450992,6.6 6.51654901,3.55271368e-15 15.416549,3.55271368e-15 C20.916549,3.55271368e-15 25.016549,1.7 33.816549,5.8 C40.316549,8.9 45.116549,11.3 49.916549,11.3 C54.616549,11.3 56.816549,7.5 56.916549,0.4 L64.516549,0.4 C65.216549,14.8 57.816549,19.9 49.816549,19.9 C44.616549,19.9 39.916549,18.4 31.016549,14.2 C24.916549,11.3 20.116549,8.5 15.416549,8.5 C10.816549,8.5 7.91654901,11.8 7.81654901,19.6 L0.0165490079,19.6 Z"
              id="Path"
              fillRule="nonzero"
            />
            <path
              d="M64.616549,57.7 L0.0165490079,57.7 L0.0165490079,23.5 L64.616549,23.5 L64.616549,57.7 Z M16.016549,31.3 L7.81654901,31.3 L7.81654901,39.4 L16.016549,39.4 L16.016549,31.3 Z M16.016549,42.1 L7.81654901,42.1 L7.81654901,50.3 L16.016549,50.3 L16.016549,42.1 Z M56.816549,31.3 L48.616549,31.3 L48.616549,39.5 L56.816549,39.5 L56.816549,31.3 Z M26.216549,31.3 L18.116549,31.3 L18.116549,39.5 L26.216549,39.5 L26.216549,31.3 Z M46.616549,31.3 L38.416549,31.3 L38.416549,39.5 L46.616549,39.5 L46.616549,31.3 Z M36.416549,31.3 L28.216549,31.3 L28.216549,39.5 L36.416549,39.5 L36.416549,31.3 Z M56.816549,42 L48.616549,42 L48.616549,50.2 L56.816549,50.2 L56.816549,42 Z M46.616549,42 L38.416549,42 L38.416549,50.2 L46.616549,50.2 L46.616549,42 Z M36.416549,42 L28.216549,42 L28.216549,50.1 L36.416549,50.1 L36.416549,42 Z M26.216549,42 L18.116549,42 L18.116549,50.2 L26.216549,50.2 L26.216549,42 Z"
              id="Shape"
            />
          </g>
        </g>
      </svg>
    </Box>
  );
}
