import axios from 'axios';

// ----------------------------------------------------------------------

const headers = {
  'Content-Type': 'application/json'
};

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  // baseURL: 'http://localhost:8080/',
  // baseURL: 'https://api.casanyehijos.es',
  // baseURL: 'https://dualstack.elb-765411696.eu-central-1.elb.amazonaws.com:443', // Este ya no se usa desde que movimos el dominio a dinahosting, ya que no soporta registros tipo NS.
  headers
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance;
