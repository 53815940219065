import { replace } from 'lodash';
import numeral from 'numeral';

// ----------------------------------------------------------------------

export function toCurrency(number, precision = 2) {
  return new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: precision
  }).format(number);
}

export function toQuantity(number) {
  return new Intl.NumberFormat('de-DE', { minimumFractionDigits: 1 }).format(number);
}

export function fCurrency(number) {
  return numeral(number).format(Number.isInteger(number) ? '0,0' : '0,0.00');
}

export function fPercent(number) {
  return numeral(number / 100).format('0.0%');
}

export function fNumber(number) {
  return numeral(number).format();
}

export function fShortenNumber(number) {
  return replace(numeral(number).format('0.00a'), '.00', '');
}

export function fData(number) {
  return numeral(number).format('0.0 b');
}
