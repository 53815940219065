import DatePicker from '@material-ui/lab/DatePicker';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack5';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Form, FormikProvider, useFormik } from 'formik';
// material
import { LoadingButton } from '@material-ui/lab';
import { Box, Card, Grid, Stack, TextField, Button, Autocomplete, Checkbox } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SaveIcon from '@material-ui/icons/Save';
import * as moment from 'moment';
import { getCustomerList } from '../../../redux/slices/customer';
import { useDispatch } from '../../../redux/store';
import axios from '../../../utils/axios';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
import Label from '../../Label';
//
import ReceiptTransferList from './ReceiptTransferList';

// ----------------------------------------------------------------------

InvoiceNewForm.propTypes = {
  isEdit: PropTypes.bool,
  serie: PropTypes.string,
  currentInvoice: PropTypes.object
};

export default function InvoiceNewForm({ isEdit, serie, currentInvoice }) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { customerList } = useSelector((state) => state.customer);
  const [receipts, setReceipts] = useState([]);
  const [rowId, setRowId] = useState(0);
  const [autoFocus, setAutoFocus] = useState(null);
  const [currentLine, setCurrentLine] = useState({});
  const { selectedCompanyId } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [customerPendingReceipts, setCustomerPendingReceipts] = useState([]);
  const [customerId, setCustomerId] = useState(1);
  const [newDate] = useState(new Date());
  const customerDefault = serie === 'C' ? 22 : 1;
  const [showIBAN, setShowIBAN] = useState(false);

  const inputRefs = useRef([]);

  const InvoiceSchema = Yup.object().shape({
    customerId: Yup.number().required('El cliente es requerido'),
    date: Yup.date().required('La fecha es requerida'),
    showIban: Yup.bool(),
    receipts: Yup.array().required('Es necesario añadir lineas para crear un albarán')
  });

  const handleSelectedReceipts = (receipts) => {
    setReceipts(receipts);
  };

  const invoiceFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      customerId: currentInvoice?.customer.id || customerDefault,
      date: currentInvoice?.date || newDate,
      showIban: currentInvoice?.showIBAN,
      receipts: currentInvoice?.receipts || []
    },
    validationSchema: InvoiceSchema,
    onSubmit: async (values, { setSubmitting, resetForm, setErrors }) => {
      const invoice = {
        customerId: values.customerId,
        date: moment(values.date),
        number: currentInvoice?.number || '',
        showIBAN,
        receipts
      };
      try {
        if (isEdit) {
          await axios.put(`/api/v1/${selectedCompanyId}/invoice/series/${serie}/${currentInvoice.id}`, invoice);
        } else {
          await axios.post(`/api/v1/${selectedCompanyId}/invoice/series/${serie}`, invoice);
        }
        resetForm();
        setSubmitting(false);
        enqueueSnackbar(!isEdit ? 'Factura creada con éxito' : 'Factura actualizada con éxito', { variant: 'success' });

        navigate(`${PATH_DASHBOARD.invoice.series}${serie}`);
      } catch (error) {
        console.error(error);
        setSubmitting(false);
        setErrors(error);
      }
    }
  });

  const getCustomerReceipts = useCallback(() => {
    try {
      return axios.get(`/api/v1/${selectedCompanyId}/customer/${customerId}/receipts`);
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Ha habido un error', { variant: 'error' });
    }
    return [];
  }, [customerId, enqueueSnackbar, selectedCompanyId]);

  useEffect(() => {
    if (customerList.length === 0) {
      dispatch(getCustomerList());
    }
  }, [customerList, dispatch]);

  useEffect(() => {
    if (currentInvoice) {
      setReceipts(currentInvoice.receipts);
      setRowId(currentInvoice.receipts.length);
      setCustomerId(currentInvoice.customer.id);
      setShowIBAN(currentInvoice.showIBAN);
    }
  }, [currentInvoice]);

  useEffect(() => {
    getCustomerReceipts().then((response) => {
      setCustomerPendingReceipts(response.data);
    });
  }, [getCustomerReceipts]);

  const { errors, values, touched, handleSubmit, isSubmitting, setFieldValue, getFieldProps } = invoiceFormik;

  return (
    <FormikProvider value={invoiceFormik}>
      <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card sx={{ p: 3 }}>
              <Stack spacing={3}>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                  <Autocomplete
                    disableClearable
                    fullWidth
                    clearOnBlur
                    options={customerList.map((option) => option)}
                    getOptionLabel={(option) => option.name}
                    value={customerList.filter((customer) => customer.id === values.customerId)[0] || 1}
                    noOptionsText="Sin opciones"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Seleccionar cliente..."
                        InputProps={{ ...params.InputProps, type: 'search' }}
                        error={Boolean(touched.customerId && errors.customerId)}
                        helperText={touched.customerId && errors.customerId}
                      />
                    )}
                    onChange={(event, newValue) => {
                      setFieldValue('customerId', newValue.id);
                      setCustomerId(newValue.id);
                    }}
                  />
                  <DatePicker
                    label="Fecha"
                    {...getFieldProps('date')}
                    onChange={(date) => setFieldValue('date', date)}
                    renderInput={(params) => (
                      <TextField fullWidth {...params} error={Boolean(touched.date && errors.date)} />
                    )}
                    inputFormat="dd/MM/yyyy"
                  />
                </Stack>

                <Stack>
                  <ReceiptTransferList
                    customerPendingReceipts={customerPendingReceipts}
                    invoiceReceipts={receipts}
                    handleSelectedReceipts={handleSelectedReceipts}
                  />
                </Stack>

                <Box sx={{ mt: 3, display: 'flex', justifyContent: 'space-between' }}>
                  <Button
                    color="primary"
                    variant="outlined"
                    component={RouterLink}
                    to={`${PATH_DASHBOARD.invoice.series}${serie}`}
                    startIcon={<ArrowBackIcon />}
                  >
                    Volver
                  </Button>
                  <Box>
                    <Label sx={{ height: 40 }} variant="outlined">
                      Mostrar número de cuenta
                      <Checkbox
                        checked={showIBAN}
                        onChange={(event) => {
                          setShowIBAN(event.target.checked);
                        }}
                      />
                    </Label>
                    <LoadingButton
                      sx={{ ml: 2 }}
                      type="submit"
                      loading={isSubmitting}
                      loadingPosition="start"
                      startIcon={<SaveIcon />}
                      variant="contained"
                    >
                      {!isEdit ? 'Crear factura' : 'Guardar cambios'}
                    </LoadingButton>
                  </Box>
                </Box>
              </Stack>
            </Card>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}
