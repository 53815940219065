import { lazy, Suspense } from 'react';
import { Navigate, useLocation, useRoutes } from 'react-router-dom';
import CompanyDialog from '../components/CompanyDialog';
// components
import LoadingScreen from '../components/LoadingScreen';
import AuthGuard from '../guards/AuthGuard';
// guards
import GuestGuard from '../guards/GuestGuard';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// layouts
import InvoiceCreate from '../pages/dashboard/invoice/InvoiceCreate';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/dashboard');

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed'
            })
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          )
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          )
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'verify', element: <VerifyCode /> }
      ]
    },

    // Dashboard Routes
    {
      path: '/',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: '/', element: <Navigate to="/dashboard" replace /> },
        // { path: 'app', element: <GeneralBanking /> },
        { path: 'dashboard', element: <CompanyDialog /> },
        {
          path: 'invoice',
          children: [
            { path: '/', element: <Navigate to="/invoice/series/V" replace /> },
            { path: 'series/T', element: <TicketList /> },
            { path: 'series/:serie', element: <InvoiceList /> },
            { path: 'series/T/new', element: <TicketCreate /> },
            { path: 'series/:serie/new', element: <InvoiceCreate /> },
            { path: '/series/T/:number/edit', element: <TicketCreate isEdit /> },
            { path: '/series/:serie/:number/edit', element: <InvoiceCreate isEdit /> }
          ]
        },
        {
          path: 'amend',
          children: [
            { path: '/', element: <Navigate to="/amend/series/R" replace /> },
            { path: 'series/:serie', element: <InvoiceList /> },
            { path: 'series/:serie/new', element: <InvoiceCreate /> },
            { path: '/series/:serie/:number/edit', element: <InvoiceCreate isEdit /> }
          ]
        },
        {
          path: 'receipt',
          children: [
            { path: '/', element: <Navigate to="/receipt/series/A" replace /> },
            { path: 'series/:serie', element: <ReceiptList /> },
            { path: 'series/:serie/new', element: <ReceiptCreate /> },
            { path: '/series/:serie/:number/edit', element: <ReceiptCreate isEdit /> }
          ]
        },
        {
          path: 'quote',
          children: [
            { path: '/', element: <Navigate to="/quote/series/P" replace /> },
            { path: 'series/:serie', element: <QuoteList /> },
            { path: 'series/:serie/new', element: <QuoteCreate /> },
            { path: '/series/:serie/:number/edit', element: <QuoteCreate isEdit /> }
          ]
        },
        {
          path: 'user',
          children: [
            { path: '/', element: <Navigate to="/dashboard/user/list" replace /> },
            { path: 'list', element: <ComingSoon /> },
            { path: 'query', element: <ComingSoon /> },
            { path: 'new', element: <ComingSoon /> },
            { path: '/:name/edit', element: <ComingSoon /> }
          ]
        },
        {
          path: 'customer',
          children: [
            { path: '/', element: <Navigate to="/dashboard/customer/list" replace /> },
            { path: 'list', element: <CustomerList /> },
            { path: 'new', element: <CustomerCreate /> },
            { path: '/:id/account', element: <CustomerAccount /> },
            { path: 'reports', element: <CustomerReports /> }
          ]
        },
        {
          path: 'product',
          children: [
            { path: '/', element: <Navigate to="/dashboard/product/list" replace /> },
            { path: 'list', element: <ProductList /> },
            { path: 'query', element: <ComingSoon /> },
            { path: 'new', element: <ProductCreate /> },
            { path: '/:id/account', element: <ProductCreate isEdit /> }
          ]
        },
        { path: 'settings', element: <ComingSoon /> }
      ]
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoon /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/authentication/Login')));
const Register = Loadable(lazy(() => import('../pages/authentication/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/authentication/ResetPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/authentication/VerifyCode')));
// Dashboard
const CustomerList = Loadable(lazy(() => import('../pages/dashboard/customer/CustomerList')));
const CustomerReports = Loadable(lazy(() => import('../pages/dashboard/customer/CustomerReports')));
const CustomerCreate = Loadable(lazy(() => import('../pages/dashboard/customer/CustomerCreate')));
const CustomerAccount = Loadable(lazy(() => import('../pages/dashboard/customer/CustomerAccount')));
const ReceiptList = Loadable(lazy(() => import('../pages/dashboard/receipt/ReceiptList')));
const ReceiptCreate = Loadable(lazy(() => import('../pages/dashboard/receipt/ReceiptCreate')));
const TicketList = Loadable(lazy(() => import('../pages/dashboard/ticket/TicketList')));
const TicketCreate = Loadable(lazy(() => import('../pages/dashboard/ticket/TicketCreate')));
const InvoiceList = Loadable(lazy(() => import('../pages/dashboard/invoice/InvoiceList')));
const ProductList = Loadable(lazy(() => import('../pages/dashboard/product/ProductList')));
const ProductCreate = Loadable(lazy(() => import('../pages/dashboard/product/ProductCreate')));
const QuoteList = Loadable(lazy(() => import('../pages/dashboard/quote/QuoteList')));
const QuoteCreate = Loadable(lazy(() => import('../pages/dashboard/quote/QuoteCreate')));
// Main
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
