import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import userReducer from './slices/user';
import customerReducer from './slices/customer';
import receiptReducer from './slices/receipt';
import invoiceReducer from './slices/invoice';
import productReducer from './slices/product';
import ticketReducer from './slices/ticket';
import quoteReducer from './slices/quote';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: []
};

const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['productList']
};

const customerPersistConfig = {
  key: 'customer',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['customerList']
};

const receiptPersistConfig = {
  key: 'receipt',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['receiptList']
};

const invoicePersistConfig = {
  key: 'invoice',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['invoiceList']
};

const ticketPersistConfig = {
  key: 'ticket',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['ticketList']
};

const quotePersistConfig = {
  key: 'quote',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['quoteList']
};

const rootReducer = combineReducers({
  user: userReducer,
  customer: persistReducer(customerPersistConfig, customerReducer),
  receipt: persistReducer(receiptPersistConfig, receiptReducer),
  invoice: persistReducer(invoicePersistConfig, invoiceReducer),
  product: persistReducer(productPersistConfig, productReducer),
  ticket: persistReducer(ticketPersistConfig, ticketReducer),
  quote: persistReducer(quotePersistConfig, quoteReducer)
});

export { rootPersistConfig, rootReducer };
