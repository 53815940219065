import { useParams, useLocation } from 'react-router-dom';
// material
import { Container } from '@material-ui/core';
import InvoiceNewForm from '../../../components/_dashboard/invoice/InvoiceNewForm';
// redux
import { useSelector } from '../../../redux/store';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// hooks
import useSettings from '../../../hooks/useSettings';
// components
import Page from '../../../components/Page';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';

// ----------------------------------------------------------------------

export default function InvoiceCreate() {
  const { themeStretch } = useSettings();
  const { pathname } = useLocation();
  const { serie, number } = useParams();
  const { invoiceList } = useSelector((state) => state.invoice);
  const isEdit = pathname.includes('edit');
  const currentInvoice = invoiceList.find((invoice) => invoice.id === parseInt(number, 10));

  return (
    <Page title="Factura: Crear nueva factura">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={!isEdit ? `Crear nueva factura serie: ${serie}` : 'Editar factura'}
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.root },
            { name: `Listado Facturas ${serie}`, href: `${PATH_DASHBOARD.invoice.series}${serie}` },
            { name: !isEdit ? 'Nueva factura' : `Editar factura ${currentInvoice?.invoiceNumber}` }
          ]}
          key="invoice"
        />

        <InvoiceNewForm isEdit={isEdit} serie={serie} currentInvoice={currentInvoice} />
      </Container>
    </Page>
  );
}
